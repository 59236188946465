import { CommandBarClientSDK } from './CommandBarClientSDK';
import { CommandBarSDK } from './CommandBarSDK';

declare global {
  interface Navigator {
    brave?: {
      isBrave(): Promise<boolean>;
    };
  }

  interface Window {
    CommandBar: CommandBarClientSDK;
    _CommandBarTmpQueueProcessing: CommandBarSDK | undefined;
    Navigator: Navigator;
    _cbIsWebView?: boolean;
  }
}

export { initProxySDK, getProxySDK } from './proxy';

/**
 * Returns the CommandBar global SDK.
 * The SDK object might be a proxy instead if `initSDK` has not yet been called.
 */
export function getSDK(): CommandBarSDK {
  return window._CommandBarTmpQueueProcessing || (window.CommandBar as unknown as CommandBarSDK);
}

/**
 * Returns the CommandBar global, coerced to match the client interface.
 * The SDK object might be a proxy instead if `initSDK` has not yet been called.
 */
export function getClientSDK(): CommandBarClientSDK {
  return window.CommandBar as CommandBarClientSDK;
}

export const SDK_INTERNAL_PREFIX = '__commandbar_INTERNAL_';
