const Z_INDEX_MAX = 2147483647;

const Z_LAUNCHER = Z_INDEX_MAX;

const Z_COMMANDBAR = Z_INDEX_MAX - 2;
const Z_EDITOR = Z_INDEX_MAX - 3;
const Z_EDITOR_OVERLAY = Z_INDEX_MAX - 2;
const Z_CHECKLIST = Z_INDEX_MAX - 3;
const Z_NUDGE = Z_EDITOR - 1;

const Z_HELPHUB = Z_EDITOR;
const Z_HELPHUB_LAUNCHER = Z_HELPHUB - 1;
const Z_EDITOR_BANNER = Z_HELPHUB - 1;

const Z_TOOLTIPS = Z_INDEX_MAX;
const Z_MESSAGES = Z_INDEX_MAX;
const Z_DRAGGERS = Z_INDEX_MAX;
const Z_MODALS = Z_INDEX_MAX;
const Z_RECORDER = Z_INDEX_MAX - 1;
const Z_DROPDOWNS = Z_INDEX_MAX - 1;
const Z_KEYBOARD_SHORTCUTS = Z_INDEX_MAX - 1;
const Z_CONFETTI = Z_INDEX_MAX;

const Z = {
  Z_INDEX_MAX,
  Z_LAUNCHER,
  Z_MESSAGES,
  Z_COMMANDBAR,
  Z_EDITOR,
  Z_EDITOR_OVERLAY,
  Z_DROPDOWNS,
  Z_TOOLTIPS,
  Z_MODALS,
  Z_RECORDER,
  Z_DRAGGERS,
  Z_KEYBOARD_SHORTCUTS,
  Z_CONFETTI,
  Z_CHECKLIST,
  Z_HELPHUB,
  Z_HELPHUB_LAUNCHER,
  Z_EDITOR_BANNER,
  Z_NUDGE,
};

export default Z;
