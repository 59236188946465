import { isDisposed } from '../util/Disposable';
import { CommandBarClientSDK, ASYNC_METHODS_SNIPPET } from './CommandBarClientSDK';
import { CommandBarProxyGlobal, CommandBarProxySDK } from './CommandBarProxySDK';
import { _configuration, _disposed, _isProxy, _queue, _unwrap, _eventSubscriptions, _sentry } from './symbols';

/**
 * Returns the "pre-boot" proxied SDK object, creating it if necessary. This object might actually be a full SDK if it
 * was already upgraded using `initSDK`. In either case, the returned object will _not_ itself be a proxy.
 *
 * The proxy SDK will only be created if one of the following hold:
 * - window.CommandBar === undefined
 * - window.CommandBar[_disposed] === true
 * - window.CommandBar.disposed === true
 *
 * Otherwise the existing value of `window.CommandBar` is returned, whatever that may be.
 */
export function getProxySDK(): CommandBarProxyGlobal {
  const proxy = window.CommandBar as unknown as CommandBarProxySDK;
  // XXX: This function shouldn't have the side effect of initializing the SDK.
  if (isDisposed(proxy) || typeof proxy[_isProxy] !== 'boolean') initProxySDK();
  return (window.CommandBar as unknown as CommandBarProxySDK)[_unwrap]();
}

/** Replaces `window.CommandBar` with a new Proxy SDK object. */
// XXX: This function shouldn't re-initialize the SDK if it has already been initialized, similar to the check in getProxySDK.
export function initProxySDK(): void {
  const legacyProxy = window.CommandBar as unknown as Record<string, unknown>;
  const existingProxy = window.CommandBar as unknown as CommandBarProxySDK;

  let orgId: string | undefined;
  let queue: unknown[][] | undefined;
  let apiOrigin: string | undefined;

  if (Array.isArray(legacyProxy?.q) || typeof legacyProxy?.uid === 'string') {
    orgId = legacyProxy?.uid as string;
    queue = legacyProxy?.q as unknown[][];
    apiOrigin = legacyProxy?.api as string;
  } else {
    orgId = existingProxy?.[_configuration]?.uuid;
    queue = existingProxy?.[_queue];
    apiOrigin = existingProxy?.[_configuration]?.api;
  }

  const proxy: CommandBarProxyGlobal = {
    [_configuration]: {
      uuid: typeof orgId === 'string' ? orgId : '',
      api: typeof apiOrigin === 'string' ? apiOrigin : undefined,
    },
    [_disposed]: false,
    [_isProxy]: true,
    [_queue]: Array.isArray(queue) ? queue : [],
    [_unwrap]: () => proxy,
    [_eventSubscriptions]: undefined,
    [_sentry]: undefined,
  };

  const sdk = proxy as unknown as CommandBarClientSDK;

  Object.assign(proxy, {
    shareCallbacks: () => ({}),
    shareContext: () => ({}),
  });

  window.CommandBar = new Proxy(proxy, {
    get: function (_, prop: keyof CommandBarClientSDK) {
      if (prop in sdk) return sdk[prop];
      if ((prop as any) === 'then') return undefined;
      if (ASYNC_METHODS_SNIPPET.includes(prop)) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const a = Array.prototype.slice.call(arguments);
          return new Promise((resolve, reject) => {
            a.unshift(prop, resolve, reject);
            proxy[_queue].push(a);
          });
        };
      }
      return function () {
        // eslint-disable-next-line prefer-rest-params
        const a = Array.prototype.slice.call(arguments);
        a.unshift(prop);
        proxy[_queue].push(a);
      };
    },
  }) as unknown as CommandBarClientSDK;
}
