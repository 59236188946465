import React from 'react';
import ReactDOM from 'react-dom';

import Drawer from 'rc-drawer';
import EditorHandle from './EditorHandle';
import Frame from './Frame';

import { getProxySDK } from '@commandbar/internal/client/globals';
import { _configuration } from '@commandbar/internal/client/symbols';

import useWindowSize from '@commandbar/internal/util/useWindowSize';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import Z from '@commandbar/internal/client/Z';
import WindowStorage from '@commandbar/internal/util/WindowStorage';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

import './Editor.css';

import type { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { TUpdateEditorRouteDetails } from '@commandbar/internal/util/dispatchCustomEvent';
import Sender from './management/Sender';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { NUDGE_STEP_PARAM } from '@commandbar/internal/util/location';

interface IEmbeddedEditorProps {
  src?: string;
  toggleEditor: () => void;
  open: boolean;
}

/*******************************************************************************/
/* Constants                                                                   */

const MINIMUM_EDITOR_WIDTH = 525;

/*******************************************************************************/

const Editor = (props: IEmbeddedEditorProps) => {
  const windowSize = useWindowSize();
  const [editorWidth, setEditorWidth] = React.useState(parseInt(LocalStorage.get('width', '770') as string));
  const [isResizing, setIsResizing] = React.useState(false);
  const apiUrl = getProxySDK()[_configuration]?.api;
  const shouldMount = !!props.src;

  const [openOverideHack, setOpenOverrideHack] = React.useState<boolean | null>(null);
  const reapplyLevelMove = () => {
    // calls to ReactDOM.flushSync ensures these aren't batched
    ReactDOM.flushSync(() => {
      setOpenOverrideHack(false);
    });
    ReactDOM.flushSync(() => {
      setOpenOverrideHack(true);
    });
    ReactDOM.flushSync(() => {
      setOpenOverrideHack(null);
    });
  };

  let src = props.src;
  if (!!src && !!apiUrl && (apiUrl !== 'https://api.commandbar.com' || props.src?.includes('localhost'))) {
    src = src.split('?')[0];
    src = `${src}?api=${apiUrl}`;
  }

  /********************************************************************************/
  /*                 Command Bar and Launcher Repositioning
  /********************************************************************************/

  React.useEffect(() => {
    const modalNudgeMock = document.getElementsByClassName('commandbar-nudge-modal-mock')[0];

    if (modalNudgeMock) {
      if (props.open) {
        modalNudgeMock.setAttribute('style', `margin-right: ${editorWidth + 8}px`);
      } else {
        modalNudgeMock.setAttribute('style', 'margin-right: 0');
      }
    }
  }, [props.open]);

  React.useEffect(() => {
    const nudgeSteps = document.getElementsByClassName('commandbar-popover-nudge-center');

    Array.from(nudgeSteps).forEach((nudgeStep) => {
      if (nudgeStep instanceof HTMLElement) {
        if (props.open) {
          const openEditorAdjustment = (editorWidth + 16) / 2;
          nudgeStep.style.right = `${openEditorAdjustment}px`;
        } else {
          nudgeStep.style.right = '0';
          nudgeStep.style.transition = 'right cubic-bezier(0.78, 0.14, 0.15, 0.86) 0.3s';
        }
      }
    });
  }, [props.open]);

  React.useEffect(() => {
    const popovers = document.getElementsByClassName('commandbar-popover-nudge-right');

    Array.from(popovers).forEach((popover) => {
      if (popover instanceof HTMLElement) {
        if (props.open) {
          popover.style.right = `${editorWidth + 16}px`;
        } else {
          popover.style.right = '0';
          popover.style.transition = 'right cubic-bezier(0.78, 0.14, 0.15, 0.86) 0.3s';
        }
      }
    });
  }, [props.open]);

  // Calculates how much to horizontally translate the CommandBar when the Editor is open
  // We default to 1 because rc-drawer treats 0 as a special case
  // If the Editor is narrower than the right half of the screen not occupied by the CommandBar, then it doesn't have to move
  // Otherwise, move the CommandBar to the edt of the expanded Editor
  const calcLevelMoveCommandBar = (target: HTMLElement) => {
    const commandBarWidth = target.getBoundingClientRect().width;
    const spaceToTheRight = (windowSize.width - commandBarWidth) / 2 - 140;

    // NOTE: on Chrome, it seems that text in the Bar is sometimes blurry
    // if this isn't a whole number of pixels. Perhaps related to this issue:
    //   https://bugs.chromium.org/p/chromium/issues/detail?id=1280484
    //   https://bugs.chromium.org/p/chromium/issues/detail?id=521364#c108
    //
    // Use Math.round(...) to prevent subpixel rendering.
    return spaceToTheRight < editorWidth ? Math.round(editorWidth - spaceToTheRight) : 0;
  };

  const calcLevelMove = (target: HTMLElement): number => {
    if (props.open) {
      const bounds = target.getBoundingClientRect();
      const offsetRight = window.innerWidth - bounds.x - bounds.width;
      const isMoved = target.dataset.levelMoved === 'true';

      // We check whether or not the element has already been moved since in this case,
      // it will fail this first check and its offset will be reset
      if (offsetRight < editorWidth || isMoved) {
        target.dataset.levelMoved = 'true';
        return editorWidth;
      } else {
        return 0;
      }
    } else {
      delete target.dataset.levelMoved;
      return 0;
    }
  };

  const calcLevelMoveHelpHub = (target: HTMLElement) => {
    const launcherBounds = target.getBoundingClientRect();
    const offsetRight = window.innerWidth - launcherBounds.x - launcherBounds.width;
    const editorHandleWidth = document.getElementById('editor_nav')?.getBoundingClientRect().width || 170;

    return offsetRight < editorWidth ? Math.round(editorWidth + editorHandleWidth + offsetRight) : 0;
  };

  const levelMove = (e: { target: HTMLElement }): [number, number] => {
    if (e.target.className.includes('commandbar-dialog')) {
      return [calcLevelMoveCommandBar(e.target), 1];
    } else if (e.target.className.includes('commandbar-launcher')) {
      return [calcLevelMove(e.target), 1];
    } else if (e.target.className.includes('commandbar-popover-container')) {
      return [calcLevelMoveCommandBar(e.target), 1];
    } else if (e.target.className.includes('commandbar-checklist')) {
      return [calcLevelMove(e.target), 1];
    } else if (e.target.className.includes('commandbar-helphub-levelmove')) {
      return [calcLevelMoveHelpHub(e.target), 1];
    } else {
      return [0, 1];
    }
  };

  /********************************************************************************/
  /*                 Dragger and resizing
  /********************************************************************************/

  const handleMouseDown = (_e: any) => {
    const frame = WindowStorage.frames?.['commandbar-editor'];
    if (!!frame) {
      frame.style.pointerEvents = 'none';
    }
    setIsResizing(true);
  };

  const handleMouseMove = (e: any) => {
    if (!isResizing) {
      return;
    }

    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    const minWidth = MINIMUM_EDITOR_WIDTH;
    const maxWidth = document.body.clientWidth - 100;
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setEditorWidth(offsetRight);
    }
  };

  const handleMouseUp = (_e: any) => {
    if (isResizing) {
      const frame = WindowStorage.frames?.['commandbar-editor'];
      if (!!frame) {
        frame.style.pointerEvents = 'auto';
      }
      LocalStorage.set('width', editorWidth);
      setIsResizing(false);

      // Trigger levelMove from rc-drawer
      // moves .commandbar-dialog and .commandbar-launcher to the left of the drawer
      reapplyLevelMove();
    }
  };

  // this is triggered elsewhere via window.CommandBar[_updateEditorRoute](details: TUpdateEditorRouteDetails)
  // which includes either details of a nudge or a questlist. In between, in sdk.tsx, we use create and
  // dispatch a custome event which gets listened for here as "updateEditorRoute"
  const handleUpdateEditorRoute: EventListener = (e) => {
    const customEvent = e as CustomEvent<TUpdateEditorRouteDetails>;
    const detail = customEvent.detail as TUpdateEditorRouteDetails;

    if (detail.type === 'nudge') {
      Sender.onRouteChange(
        `${editorRoutes.getNudgeRoute(detail.nudge)}/${detail.nudge.id}?${NUDGE_STEP_PARAM}=${detail.stepIndex}`,
      );
    } else if (detail.type === 'checklist') {
      Sender.onRouteChange(`${editorRoutes.CHECKLIST_ROUTE}/${detail.checklistId}`);
    } else if (detail.type === 'route') {
      Sender.onRouteChange(`${detail.route_replacement}`);
    }
  };

  React.useEffect(() => {
    window.addEventListener('updateEditorRoute', handleUpdateEditorRoute);

    return () => {
      window.removeEventListener('updateEditorRoute', handleUpdateEditorRoute);
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  });

  React.useEffect(() => {
    const handle = (_e: any) => {
      setTimeout(() => {
        if (props.open) {
          reapplyLevelMove();
        }
      }, 200);
    };

    document.addEventListener('CommandBar.opened', handle);
    document.addEventListener('commandbar-launcher-ready', handle);
    document.addEventListener('commandbar-checklist-shown', handle);
    document.addEventListener('commandbar-helphub-shown', handle);

    return () => {
      document.removeEventListener('CommandBar.opened', handle);
      document.removeEventListener('commandbar-launcher-ready', handle);
      document.removeEventListener('commandbar-checklist-shown', handle);
      document.removeEventListener('commandbar-helphub-shown', handle);
    };
  }, [props.open]);

  React.useEffect(() => {
    window.postMessage('commandbar-editor-loaded', '*');
  }, []);

  /********************************************************************************/

  const [isEditorLoggedIn, setIsEditorLoggedIn] = React.useState(false);
  const [organization, setOrganization] = React.useState<IOrganizationType | null>(null);
  const [releaseBadgeStatus, setReleaseBadgeStatus] = React.useState<boolean>(false);

  const handleEditorAuthStatus: ReceiverFunctionType = ({ data }) => {
    setIsEditorLoggedIn(data.data);

    if (!data.data) {
      setOrganization(null);
    }

    return respondSuccess();
  };

  const handleOrganizationChange: ReceiverFunctionType = ({ data }) => {
    setOrganization(data.data?.organization);

    return respondSuccess();
  };

  const handleReleaseBadgeStatusChange: ReceiverFunctionType = ({ data }) => {
    setReleaseBadgeStatus(data.data);
    return respondSuccess();
  };

  usePortal({
    editor: {
      shareUserAuthStatus: handleEditorAuthStatus,
      shareOrganization: handleOrganizationChange,
      shareReleaseBadgeStatus: handleReleaseBadgeStatusChange,
    },
  });

  /********************************************************************************/

  if (!shouldMount) {
    return null;
  }
  return (
    <Drawer
      prefixCls="commandbar-editor-drawer"
      placement="right"
      open={openOverideHack !== null ? openOverideHack : props.open}
      width={`${editorWidth + 10}px`}
      level={[
        '.commandbar-dialog',
        '.commandbar-launcher',
        '.commandbar-popover-container',
        '.commandbar-checklist',
        '.commandbar-helphub-levelmove',
      ]}
      levelMove={levelMove}
      handler={
        <EditorHandle
          isEditorLoggedIn={isEditorLoggedIn}
          organization={organization}
          isEditorOpen={props.open}
          toggleEditor={props.toggleEditor}
          releaseBadgeStatus={releaseBadgeStatus}
        />
      }
      style={{
        outline: 'none',
        // HACK: For some reason, the Drawer component doesn't respect the zIndex and
        // an additional step up is needed for the drawer handle
        zIndex: Z.Z_EDITOR + 1,
      }}
      maskClosable={false}
      showMask={false}
      autoFocus={false}
      id="commandbar-editor-drawer"
      data-testid="commandbar-editor-drawer"
    >
      <div
        id="dragger"
        onMouseDown={(event) => {
          handleMouseDown(event);
        }}
        style={{
          width: '5px',
          cursor: 'ew-resize',
          padding: '4px 0 0',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: Z.Z_DRAGGERS,
          backgroundColor: 'transparent',
          margin: '11px 0px',
        }}
      />
      <Frame
        id="editor"
        src={src}
        allow={'clipboard-read; clipboard-write'}
        width={'98%'}
        height={'100%'}
        style={{ border: '0px', backgroundColor: 'rgb(242,242,242)', borderRadius: 12 }}
      />
    </Drawer>
  );
};

export default Editor;
