export const getQueryStringType = (x: string): 'css' | 'xpath' | 'pause' | 'blur' => {
  if (x.startsWith('pause')) {
    return 'pause';
  } else if (x.startsWith('blur')) {
    return 'blur';
  } else if (x.substring(0, 2) === '//') {
    return 'xpath';
  } else {
    return 'css';
  }
};

// https://stackoverflow.com/a/14284815/1569490
export const loadFromXPath = (x: string, doc: Document | null = null, logError?: (x: string) => void) => {
  if (typeof x !== 'string' || x.length === 0) return null;

  if (!doc) doc = document;

  try {
    const xPathRes = doc.evaluate(x, doc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
    if (xPathRes.singleNodeValue) {
      return xPathRes.singleNodeValue;
    } else {
      return null;
    }
  } catch (e) {
    if (logError) {
      logError(x);
    }
    return null;
  }
};

export const loadFromSelector = (x: string, doc: Document | null = null, logError?: (x: string) => void) => {
  if (typeof x !== 'string' || x.length === 0) return null;

  if (!doc) doc = document;

  try {
    const elem = doc.querySelector(x);
    return elem;
  } catch (e) {
    if (logError) {
      logError(x);
    }
    return null;
  }
};

export const checkSelector = (selector: string): boolean => !!getElement(selector);

export function clickElementsWithPause(index: number, selectors: string[], logError?: (x: string) => void) {
  if (index >= selectors.length) {
    return;
  }

  let pause = 150;
  let blur = false;

  const element = getElement(selectors[index]);
  const type = getQueryStringType(selectors[index]);

  if (type === 'pause') {
    pause = parseInt(selectors[index].replace(/^pause/, '')) ?? 150;
  } else if (type === 'blur') {
    blur = true;
  }

  if (!element) {
    logError?.(selectors[index]);
    return;
  }

  if (element instanceof HTMLElement) {
    element.click();
  } else if (element instanceof SVGElement) {
    element.dispatchEvent(new MouseEvent('click', { bubbles: true }));
  }
  if (blur) {
    // Blurring syntax: `blur element`
    const selector = selectors[index].replace(/^blur /, '');
    let count = 0;

    const intervalID = setInterval(() => {
      count += 1;
      const element = getElement(selector);

      if (element === document?.activeElement && element instanceof HTMLElement) {
        element.blur();
        clearInterval(intervalID);
      }

      // Poll for a maximum of 2 seconds (10 ms interval x 200 iterations)
      if (count > 200) {
        clearInterval(intervalID);
      }
    }, 10);
  }
  setTimeout(() => {
    clickElementsWithPause(index + 1, selectors, logError);
  }, pause);
}

/******************************************************************/

export function clickElement(selector: string, logError?: (x: string) => void) {
  const element = getElement(selector);

  if (!element) {
    if (logError) {
      logError(selector);
    }
    return;
  }

  if (element instanceof HTMLElement) {
    element.click();
  } else if (element instanceof SVGElement) {
    element.dispatchEvent(new MouseEvent('click', { bubbles: true }));
  }
}

/******************************************************************/

/*
 * If we encounter a selector string in this format, then we should treat it as a nested shadow DOM selector.
 * Each comma separated element is a query to a shadow root. The tail is the target element.
 */
type ShadowDOMSelector = `[${string},${string}]`;

// Parse a string for compliance with ShadowDOMSelector
const isShadowDOMSelector = (selector: string): selector is ShadowDOMSelector => /^\[[^,]+(,[^,]+)+\]$/.test(selector);
// Split a ShadowDOMSelector into an array of selectors
const splitShadowDOMSelector = (selector: ShadowDOMSelector): string[] => selector.slice(1, -1).split(',');

const getNextContext = (context: Document | Element | ShadowRoot, selector: string): Element | null => {
  if (context instanceof Element) {
    return (context.querySelector(selector) || context.shadowRoot?.querySelector(selector)) ?? null;
  }

  return context instanceof Document || context instanceof ShadowRoot ? context.querySelector(selector) : null;
};

// Recursively select elements within shadow DOMs
const deepSelectShadowDOMElement = (
  selectors: string[],
  currentContext: Document | Element | ShadowRoot = document,
): Document | Element | ShadowRoot | undefined => {
  if (selectors.length === 0) {
    return currentContext;
  }

  const [currentSelector] = selectors;
  const nextContext = getNextContext(currentContext, currentSelector);

  if (nextContext) {
    return deepSelectShadowDOMElement(selectors.slice(1), nextContext);
  }
};

export const getElement = (selector: string, doc?: Document): Element | undefined => {
  const type = getQueryStringType(selector);

  if (isShadowDOMSelector(selector)) {
    const shadowDOMPath = splitShadowDOMSelector(selector);
    const node = deepSelectShadowDOMElement(shadowDOMPath);
    if (node instanceof Element) {
      return node;
    }
  }

  const node = type === 'xpath' ? loadFromXPath(selector, doc) : loadFromSelector(selector, doc);
  if (node instanceof Element) {
    return node;
  }
};

// INFO: https://regex101.com/r/yQn3ul/1
const firstQuerySelectorRegex = /\.querySelector\("([^"]+)"\)/;

// Extract selectors from a JavaScript .querySelector() path
const extractSelectors = (input: string): string => {
  // same as `firstQuerySelectorRegex` but with the global flag
  const allQuerySelectorsRegex = /\.querySelector\("([^"]+)"\)/g;

  const matches = input.match(allQuerySelectorsRegex);
  if (!matches) {
    return '[]';
  }

  const selectors = matches
    .map((match) => {
      const matchGroups = firstQuerySelectorRegex.exec(match);
      return matchGroups ? matchGroups[1] : '';
    })
    .filter(Boolean);

  return selectors.length === 1 ? selectors.toString() : `[${selectors.toString()}]`;
};

// Transform a JavaScript .querySelector() path into a ShadowDOMSelector form, if applicable
// INFO: ShadowDOMSelector | string type is redundant but used to clarify function purpose
export const transformIfJSPath = (input: string): ShadowDOMSelector | string =>
  firstQuerySelectorRegex.test(input) ? extractSelectors(input) : input;

export function getScrollParent(node: HTMLElement | null): HTMLElement | null {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentElement);
  }
}

/******************************************************************/

export function isHidden(el: HTMLElement) {
  return el.offsetParent === null;
}

/******************************************************************/

const isElementHidden = (rect: DOMRect, parentRect: DOMRect, parentStyle: CSSStyleDeclaration) => {
  const isDisplayNone = parentStyle.display === 'none';
  const isVisibilityHidden = parentStyle.visibility === 'hidden';

  const isOverflowHidden = parentStyle.overflow === 'hidden';
  const isOverflowXHidden = parentStyle.overflowX === 'hidden';
  const isOverflowYHidden = parentStyle.overflowY === 'hidden';

  const isHorizontallyHidden =
    (isOverflowHidden || isOverflowXHidden) && (rect.right < parentRect.left || rect.left > parentRect.right);
  const isVerticallyHidden =
    (isOverflowHidden || isOverflowYHidden) && (rect.bottom < parentRect.top || rect.top > parentRect.bottom);

  return isDisplayNone || isVisibilityHidden || isHorizontallyHidden || isVerticallyHidden;
};

function isVisibleOnPage(element: Element) {
  // Check if the element has display: none or visibility: hidden
  const style = window.getComputedStyle(element);
  if (style.display === 'none' || style.visibility === 'hidden') {
    return false;
  }

  // Get the element's dimensions and position in the viewport
  const rect = element.getBoundingClientRect();

  // Check if the element or any of its parents have display: none, visibility: hidden, or overflow: hidden
  let parent = element.parentNode;
  while (parent && parent !== document.body && parent instanceof Element) {
    const parentStyle = window.getComputedStyle(parent);
    const parentRect = parent.getBoundingClientRect();

    if (isElementHidden(rect, parentRect, parentStyle)) {
      return false;
    }

    parent = parent.parentNode;
  }

  // Check if the element is visible in the current viewport or can be scrolled to
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

  return vertInView && horInView;
}

export const checkSelectorAndVisibility = (selector: string): { elementFound: boolean; elementVisible: boolean } => {
  const element = getElement(selector);
  return element && element instanceof Element
    ? { elementFound: true, elementVisible: isVisibleOnPage(element) }
    : { elementFound: false, elementVisible: false };
};
