import { INudgeType, IOrganizationType } from '@commandbar/internal/middleware/types';
import { ExperienceState } from './shared';

export const PREVIEW_ELEMENT_ID = 'commandbar-extension-preview';

export type StartPreviewPayload = {
  previewUrl: string;
  experience: ExperienceState;
  organizationId?: IOrganizationType['id'];
};
export type StopPreviewPayload = {
  nudge: INudgeType;
  currentStepIndex?: number;
};

export type PreviewState = {
  enabled: boolean;
  experience?: ExperienceState;
  tabId?: chrome.tabs.Tab['id'];
};
