export const _configuration = Symbol.for('CommandBar::configuration');
export const _configure = Symbol.for('CommandBar::configure');
export const _configUser = Symbol.for('CommandBar::configUser');
export const _dispatch = Symbol.for('CommandBar::dispatch');
export const _dispose = Symbol.for('CommandBar::dispose');
export const _disposed = Symbol.for('CommandBar::disposed');
export const _instanceAttributes = Symbol.for('CommandBar::instanceAttributes');
export const _isProxy = Symbol.for('CommandBar::isProxy');
export const _loadEditor = Symbol.for('CommandBar::loadEditor');
export const _onEditorPathChange = Symbol.for('CommandBar::onEditorPathChange');
export const _shareEditorRouteWithBar = Symbol.for('CommandBar::shareEditorRouteWithBar');
export const _updateEditorPreviewDevice = Symbol.for('CommandBar::updateEditorPreviewDevice');
export const _getEditorPreviewDevice = Symbol.for('CommandBar::getEditorPreviewDevice');
export const _perf = Symbol.for('CommandBar::perf');
export const _programmaticTheme = Symbol.for('CommandBar::programmaticTheme');
export const _queue = Symbol.for('CommandBar::queue');
export const _reload = Symbol.for('CommandBar::reload');
export const _reloadCommands = Symbol.for('CommandBar::reloadCommands');
export const _reloadOrganization = Symbol.for('CommandBar::reloadOrganization');
export const _reloadPlaceholders = Symbol.for('CommandBar::reloadPlaceholders');
export const _reloadNudges = Symbol.for('CommandBar::reloadNudges');
export const _reloadHelpHub = Symbol.for('CommandBar::reloadHelpHub');
export const _previewRecommendationSet = Symbol.for('CommandBar::previewRecommendationSet');
export const _previewCopilotSettings = Symbol.for('CommandBar::previewCopilotSettings');
export const _stopRecommendationSetPreview = Symbol.for('CommandBar::stopRecommendationSetPreview');
export const _startNudgeDebug = Symbol.for('CommandBar::startNudgeDebug');
export const _getDebugSnapshot = Symbol.for('CommandBar::getDebugSnapshot');
export const _showNudgeStepMock = Symbol.for('CommandBar::showNudgeStepMock');
export const _closeNudgeMocks = Symbol.for('CommandBar::closeNudgeMocks');
export const _stopChecklistPreview = Symbol.for('CommandBar::stopChecklistPreview');
export const _stopNudgeDebug = Symbol.for('CommandBar::stopNudgeDebug');
export const _previewChecklist = Symbol.for('CommandBar::previewChecklist');
export const _reloadChecklists = Symbol.for('CommandBar::reloadChecklists');
export const _report = Symbol.for('CommandBar::report');
export const _search = Symbol.for('CommandBar::search');
export const _sentry = Symbol.for('CommandBar::sentry');
export const _setDashboard = Symbol.for('CommandBar::setDashboard');
export const _setPreviewMode = Symbol.for('CommandBar::setPreviewMode');
export const _setTestMode = Symbol.for('CommandBar::setTestMode');
export const _setEditorVisible = Symbol.for('CommandBar::setEditorVisible');
export const _shareConfig = Symbol.for('CommandBar::shareConfig');
export const _shareContextSettings = Symbol.for('CommandBar::shareContextSettings');
export const _shareProgrammaticCommands = Symbol.for('CommandBar::shareProgrammaticCommands');
export const _showGuide = Symbol.for('CommandBar::showGuide');
export const _showMessage = Symbol.for('CommandBar::showMessage');
export const _state = Symbol.for('CommandBar::state');
export const _unwrap = Symbol.for('CommandBar::unwrap');
export const _user = Symbol.for('CommandBar::user');
export const _fingerprint = Symbol.for('CommandBar::fingerprint');
export const _userProperties = Symbol.for('CommandBar::userProperties');
export const _eventSubscriptions = Symbol.for('CommandBar::eventSubscriptions');
export const _metaAttributes = Symbol.for('CommandBar::metaAttributes');
export const _updateEditorRoute = Symbol.for('CommandBar::updateEditorRoute');
export const _startDebug = Symbol.for('CommandBar::startDebug');
export const _stopDebug = Symbol.for('CommandBar::stopDebug');
export const _shareTrackedEvents = Symbol.for('CommandBar::shareTrackedEvents');
export const _setThemeV2 = Symbol.for('CommandBar::setThemeV2');
export const _showWidgetTableau = Symbol.for('CommandBar::showWidgetTableau');
