import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { NudgeExperience } from './shared';

export const RECORDER_ELEMENT_ID = 'commandbar-extension-recorder';

export type StartClickRecorderPayload = {
  previewUrl?: string;
  experience: NudgeExperience;
  origin: 'recorder' | 'preview';
  organizationId?: IOrganizationType['id'];
};

export type StopClickRecorderPayload = { value: string; experience?: NudgeExperience };

export type RecorderState = {
  enabled: boolean;
  experience?: NudgeExperience;
  tabId?: chrome.tabs.Tab['id'];
};
