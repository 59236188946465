import { ICommandFromClientType } from '../middleware/ICommandFromClientType';
import { IRecordSettings } from '../middleware/IRecordSettings';
import { DataRowMetadata } from '../middleware/detailPreview';
import { AddContextOptions, ArgumentOptions, DataRow, RecordOptions } from './AddContextOptions';

import type { DetailPreviewType, IChecklist, ICommandCategoryType, INudgeType, ISkinType } from '../middleware/types';
import type { EventHandler, EventSubscriber } from '@commandbar/commandbar/shared/services/analytics/EventHandler';
export type { DataRow } from './AddContextOptions';
export type { DataRowMetadata } from '../middleware/types';

/**
 * WARNING: We wary with adding methods to this list that have a fn as the first arg.
 * Updating ASYNC_METHODS_SNIPPET  will change how we parse a call in the queue. If a method is in this list, we assume
 * it has Promise.resolve and Promise.reject unshifted to the arguments list. */
export const ASYNC_METHODS_SNIPPET: Array<keyof CommandBarClientSDK> = [
  'addCommand',
  'boot',
  'addEventSubscriber',
  'addRecordAction',
  'setFormFactor',
];

export const ASYNC_METHODS: Array<keyof CommandBarClientSDK> = [
  'addCommand',
  'boot',
  'addEventSubscriber',
  'addEventHandler',
  'addRecordAction',
  'setFormFactor',
];

export type BootOptions = string | null | undefined | (UserProperties & { id: string | null | undefined });
export type Metadata = Record<string, unknown>;
export type Meta = {
  source: { type: 'action'; metadata: { [key: string]: any } };
};

export type CommandDetails = {
  category?: number | null;
  /**
   * The unique id of the command. For commands defined via the Editor, the value will be a number. For programmatic
   * commands, the `name` (string) provided will be used.
   */
  command: number | string | undefined;
  /** The text of the command */
  commandText: string;
  /** The source of the command. */
  source: string;
  shortcut_mac: string;
  shortcut_win: string;
  customShortcut: string | undefined;
  callbackKey: string | undefined;
};

export type UserProperties = Record<string, any>;

export type ModalFormFactor = { type: 'modal' };

export type InlineFormFactor = { type: 'inline'; rootElement: string | HTMLElement };

export type FormFactorConfig = ModalFormFactor | InlineFormFactor;
export const PRODUCTS = ['spotlight', 'nudges', 'checklists', 'help_hub'] as const;
export type ProductConfig = Array<(typeof PRODUCTS)[number]>;
export type ProductDebugOptions = {
  product: 'nudges';
};
export type FormFactor = Pick<FormFactorConfig, 'type'>;

export type InstanceAttributes = {
  canOpenEditor: boolean;
  hmac?: string;
  formFactor: FormFactorConfig;
  products: ProductConfig;
  copilotAPIHeaders?: Record<string, string>;
};

export type MetaAttributes = {
  wp_plugin_version?: string;
};

export const DEFAULT_INSTANCE_ATTRIBUTES: InstanceAttributes = {
  canOpenEditor: true,
  formFactor: { type: 'modal' },
  products: ['spotlight', 'nudges', 'checklists', 'help_hub'],
};

export const DEFAULT_META_ATTRIBUTES: MetaAttributes = {};

export type CallbackFunction<T, U = Metadata, V = Meta> = (args: T, context: U, meta?: V) => void;
export type ContextLoader = (chosenValues?: undefined | Record<string, unknown[]>) => unknown;
export type RecordsOrArgumentListLoader = (
  chosenValues?: undefined | Record<string, unknown[]>,
) => DataRow[] | Promise<DataRow[]>;
export type CallbackMap = { [name: string]: CallbackFunction<any, any> };

export type CustomComponent = {
  mount: (node: HTMLElement) => CustomComponentInstance;
};

export type CustomComponentInstance = {
  render: (data?: DataRow, metadata?: DataRowMetadata) => void;
  unmount: () => void;
};

export type CommandBarClientSDK = Readonly<{
  /**
   * Returns a list of visible CommandBar experiences along with related metadata.
   */
  activeExperiences(): Array<
    | { type: 'spotlight' }
    | { type: 'nudge'; id: INudgeType['id']; step: number }
    | { type: 'checklist'; id: IChecklist['id'] }
    | { type: 'helphub' }
  >;

  /**
   * Adds a callback function to CommandBar. The callback function provided is mapped to `callbackKey`, and can be
   * attached to commands by referencing `callbackKey` in a command config.
   *
   * @param callbackKey Key to reference the provided function
   * @param callbackFn Callback function, with the following signature:
   * * `args`: Depends on the callback, but typically a dictionary of argument keys and the values entered by the user.
   * * `context`: A dictionary reflecting the state of context when the callback was triggered by a command execution
   */
  addCallback<T, U>(callbackKey: string, callbackFn: CallbackFunction<T, U>): void;
  // FIXME: undocumented
  addCallbacks(callbacks: CallbackMap): void;
  /**
   * Add a command to CommandBar.
   *
   * @param command The command schema
   */
  // addCommand(command: CommandConfig): void;
  addCommand(command: ICommandFromClientType): Promise<void>;
  /**
   * Configure a Category
   *
   * @param category The id (or name) of the category. If you provide a name and that category doesn't exist, it will be created automatically.
   * @param config The Category config schema
   */
  setCategoryConfig(category: string | number, config: Partial<ICommandCategoryType>): void;

  /**
   * Provides a list of argument choices to CommandBar. For any keys previously set, updates values.
   *
   * @param key Key for the argument choices. Used to associate an argument with choices in the Editor.
   *
   * @param initialValue The initial choices
   *
   * There are two options:
   * * Static: A literal value (e.g. `["foo", "bar"]`)
   * * Dynamic: A function to load a set of values for `key`, used to lazily load a list of values. This function will
   * be passed as an argument a dictionary of the previously selected argument values, if the argument is not the first. Useful for
   * lazily loading longer lists, or loading lists that are dependent on previous arguments.
   *
   * If providing a list of objects, there are some reserved fields that will trigger special behavior. See DataRow type
   * for the list of those fields.
   *
   * @param options Options to customize CommandBar properties for `key`.
   *
   * @see [ArgumentOptions](#ArgumentOptions).
   */
  addArgumentChoices(
    key: string,
    initial: DataRow[] | RecordsOrArgumentListLoader | null,
    options?: ArgumentOptions,
  ): void;

  /**
   * Adds a key, value pair to CommandBar that can be referenced to customize commands: such as in command URLs, availability rules, and recommendation rules.
   *
   * @param key Key for the metadata. Used to reference metadata.
   *
   * @param value The value of the key
   *
   * There are two options:
   * * Static: A literal value (e.g. `addMetadata('isAdmin', true)`)
   * * Dynamic: A function to load a set of values for `key`, used to lazily load a value. Useful for
   * lazily loading data.
   */
  addMetadata(key: string, value: unknown | ContextLoader, /** @deprecated */ addToUserProperties?: boolean): void;
  addMetadataBatch(data: Metadata, /** @deprecated */ addToUserProperties?: boolean): void;

  // FIXME: _properties is not used anywhere
  trackEvent(key: string, _properties?: Metadata): void;

  /**
   * Resets a nudge to a specific step. If no step is provided, resets the nudge to initial step.
   * If the nudge is currently active, it will become inactive.
   *
   * @param id The id of the nudge to reset
   * @param step The step to reset the nudge to
   */
  resetNudge(id: number, step?: number): void;

  /** @deprecated Use addRecords, addArgumentChoices, or addMetadata instead. */
  addContext(key: string, initialValue: ContextLoader, options?: AddContextOptions): void;
  /** @deprecated Use addRecords, addArgumentChoices, or addMetadata instead. */
  addContext(key: string, initialValue: unknown, options?: AddContextOptions): void;
  /** @deprecated Use setContext instead. */
  addContext(ctx: Metadata): void;

  /** @deprecated Use addEventSubscriber instead. */
  addEventHandler(eventHandler: EventHandler): Promise<void>;

  /**
   * Captures CommandBar events to be handled in your code / stored in your database. Ask the CommandBar team if you're
   * interested in this feature.
   *
   * @param eventSubscriber A function for handling events generated by CommandBar. It should have the following signature:
   * * `eventName`: The name of the event type. Here are the different event types:
   *    * `opened`: Bar open
   *    * `closed`: Bar close. When the bar is closed with input text, it triggers both a `closed` event and a
   *      `abandoned_search` (deadend). Executions do not trigger `closed` events.
   *    * `abandoned_search`: A deadend
   *    * `command_suggestion`: A command suggestion
   *    * `command_execution`: A command execution
   *    * `no_results_for_query`: When a user's query does not retrieve any results
   *    * `client_error`: An error encountered by CommandBar
   *    * `shortcut_edited`: When a user assigns or edits a command's shortcut
   * * `eventData`: Event attributes (will differ based on the type of event). In addition to the data below, any
   *   [eventData you pass to .boot()](https://commandbar.com/sdk#boot-eventdata) will be added to each event.
   * @returns A function to remove the event handler
   */
  addEventSubscriber(eventSubscriber: EventSubscriber): Promise<() => void>;

  /**
   * Add a record action for a key
   *
   * @param recordKey The key for record for which to attach an action to
   *
   * @param action The record action
   *
   * @param isDefault Whether this record action should be the default. Only needs to be included if multiple record actions are provided
   *
   * @param showInDefaultList Whether this record action should also be shown in the default list as a command
   */
  // addCommand(command: CommandConfig): void;
  addRecordAction(
    recordKey: string,
    action: ICommandFromClientType,
    isDefault?: boolean,
    showInDefaultList?: boolean,
  ): Promise<void>;

  /**
   * Makes records available to CommandBar. Records are searchable data. For any keys previously set, updates values.
   *
   * @param key Key for the records. Used to refer to records in the Editor, such as when creating record actions.
   *
   * @param initialValue The initial records
   *
   * There are two options:
   * * Static: A literal value (e.g. `["foo", "bar"]`)
   * * Dynamic: A function to load a set of values for `key`, used to lazily load a list of values.       * Useful for
   * lazily loading longer lists of records.
   *
   * If providing a list of objects, there are some reserved fields that will trigger special behavior. See DataRow type
   * for the list of those fields.
   *
   * @param options Options to customize CommandBar properties for `key`.
   *
   * @see [RecordOptions](#RecordOptions).
   */
  addRecords(key: string, initial: DataRow[] | RecordsOrArgumentListLoader | null, options?: RecordOptions): void;

  /**
   * Adds a search endpoint to CommandBar that returns results for multiple record types.
   *
   * @param onInputChange search endpoint to call for the specified record keys
   * @param keys Record keys that this search endppoint covers
   */
  addMultiSearch(onInputChange: (input: string) => Promise<any>, keys: string[]): void;

  /**
   * Sets a router function that link command can use to update the page's URL without triggering a reload. To lean more about using `addRouter` see [Adding a router](https://commandbar.com/docs/dev/router).
   *
   * @param routerFn The router function. It should accept the following arguments:
   * * `url` {string} The url to navigate to
   */
  addRouter(routerFn: (url: string) => void): void;
  /** @deprecated Use addContext instead. */
  addSearch(name: string, func: (...args: unknown[]) => unknown): void;

  /**
   * Sets the user properties for the current user. These properties will be associated with the user and can be used for targeting.
   * boot() must be called before setUserProperties() can be called.
   *
   * @param userProperties Key-value pairs to be associated with the end user ID CommandBar was booted with.
   */
  setUserProperties(userProperties: UserProperties): Promise<void>;

  /**
   * Make CommandBar available to the user. CommandBar will not be available before `.boot` is called, even if the
   * snippet has been run on the page they are on.
   *
   * @param id ID corresponding to the currently logged-in end user. Used to tag analytics events. If ID is an empty string, null, or undefined, CommandBar will be booted in anonymous mode.
   * @param metadata Key-value pairs to be associated with the end user for whom CommandBar is being booted. You can
   * filter on these attributes from the analytics page in your dashboard. In addition, events passed to a supplied
   * event handler will include metadata you provide via `boot`.
   */
  boot(
    id?: string | null,
    userProperties?: UserProperties,
    instanceAttributes?: Partial<InstanceAttributes>,
  ): Promise<void>;
  /**
   * @deprecated Passing an object to boot will be deprecated in a future version of CommandBar.
   */
  boot(opts: BootOptions): Promise<void>;

  /**
   * Changes the Bar form factor; can be set to either Modal (the default) or Inline.
   *
   * @param formFactorConfig either {type: 'modal'} or {type: 'inline', rootElement: (id of an element on the page) or an HTMLElement}
   */
  setFormFactor(formFactorConfig: FormFactorConfig): Promise<void>;

  // FIXME: undocumented
  close(): void;

  /**
   * Close the HelpHub.
   */
  closeHelpHub(): void;

  /**
   * Force closes all active nudges
   * Analytics events will not be sent for nudges that are closed.
   */
  closeAllNudges(): void;

  /**
   * Executes a command. If this command has arguments, the Bar will open so the user can complete those arguments.
   *
   * @param id The command id to execute. For commands defined via the Editor, you can find this id in the Editor by selecting "Copy code" in the command's ellipsis menu. For commands defined via the SDK, this is the `name` field.
   */
  execute(id: number | string): void;

  /**
   * Defines a function
   *
   * @param fn The preview detail generator function. It should accept the following arguments:
   * * `row` {any}
   * * `node` {any}
   */
  generateDetailPreview(fn: (data: DataRow, metadata: DataRowMetadata) => undefined | DetailPreviewType): void;

  addComponent(key: string, name: string, component: CustomComponent): void;
  removeComponent(key: string): void;

  /**
   * Returns an array returning all commands that fit the optional filter function. By default, all commands are returned
   * @param filter A function that filters out commands based on its properties. These are:
   * * `command`: The unqiue id of the command. For commands defined via the Editor, the value will be a number. For programmatic commands, the name (string) provided will be used.
   * * `commandText`: The text of the command
   * * `category`: The category id of the command. Only provided if the command has a category
   * * `callbackKey`: The command's callback key, if the command has one
   * * `shortcut_mac`: Default shortcut set for macOS
   * * `shortcut_win`:Default shortcut set for Windows and Linux devices
   * * `source`: Source of the executed command
   * * `customShortcut`: Shortcut string if this command has a custom user-set shortcut
   */
  getCommands(filter?: (entry: CommandDetails) => boolean): CommandDetails[];
  /**
   * Returns `true` if the Bar is currently open, `false` if it is not, or `undefined` if the CommandBar is not fully
   * initialized yet.
   */
  isOpen(): boolean | undefined;
  /**
   * Open the Bar. If an input is supplied, will prefill the search input with that value.
   *
   * @param input Optional input ot prefill the Bar's search field.
   * @param options FIXME: UNDOCUMENTED
   */
  open(input?: string, options?: { categoryFilter?: number | string }): void;
  /**
   * Open the in-app Editor
   */
  openEditor(): void;

  /**
   * Open the HelpHub.
   */
  openHelpHub(options?: { query?: string; articleId?: number | null; chatOnly?: boolean }): void;

  openCopilot(options?: { query?: string }): void;

  /**
   * Set a filter to be used when retrieving HelpHub docs.
   */
  setHelpHubFilter(filter: { labels?: string[] }): void;

  /**
   * Clear the filter to be used when retrieving HelpHub docs; will allow all docs to be searched
   */
  clearHelpHubFilter(): void;

  /**
   * Open/close the Help Hub.
   */
  toggleHelpHub(): void;

  /**
   * Removes the callback function referenced by `callbackKey` from CommandBar.
   *
   * When you remove a callback, any commands for which the callback is a dependency will become unavailable. Learn
   * more about availability [here](https://commandbar.com/docs/commands/availability).
   *
   * @param callbackKey Callback key for the callback to be removed.
   */
  removeCallback(callbackKey: string): void;
  /**
   * Remove a command, making it unavailable to a user.
   *
   * @param commandName The `name` field of the command
   */
  removeCommand(commandName: string): void;
  /**
   * Removes a key and its corresponding value from context. When you remove a context key, any commands for which the
   * key was a depencdency will become unavailable. To learn more about availability, see
   * [When are commands available to users?](https://commandbar.com/docs/commands/availability)
   *
   * @param keyToRemove Context key to remove
   */
  removeContext(keyToRemove: string): void;
  // REVIEW: pending product decision to keep
  /**
   * Overrides certain components with provided options. Ask the CommandBar team if you're interested in this feature.
   * Custom UI components are only available for enterprise customers. Please contact CommandBar.
   * @param slug Component slug
   * @param getHTML Function that returns an html string to be rendered as the component
   */
  setCustomComponent(
    slug:
      | 'header'
      | 'menuHeader'
      | 'tabHeader'
      | 'input'
      | 'sidepanel'
      | 'footer'
      | 'navPaneHeader'
      | 'navPaneFooter'
      | 'defaultState'
      | 'emptyState',
    getHTML: (meta?: {
      step?: 'base' | 'multiselect' | 'longtextinput' | 'textinput' | 'select' | 'dashboard';
      majorCategory?: string;
    }) => string | CustomComponent,
  ): void;
  /**
   * Similar to `addContext`, but also removes any keys that are omitted from the supplied `ctx` from context. It may be
   * helpful to use this to make large context changes, such as when a user logs out.
   *
   * @param context Dictionary to overwrite context
   * @param meta FIXME: UNDOCUMENTED
   */
  setContext(context: Metadata, meta?: Metadata & { useCustom?: boolean; customID?: string | number }): void;
  /**
   * This method will override the hotkey used by the current user to summon CommandBar.
   *
   * Please note that this method will only work if your Organization as End User Customizable Shortcuts enabled.
   *
   * @param {string} hotkey Any mousetrap.js compliant string (e.g. 'mod+k')
   * @returns {boolean} Returns false if the hotkey string is invalid
   */
  setSummonHotkey(hotkey: string | null): boolean;
  /**
   * Changes the background and primary color of the CommandBar to the supplied color.
   *
   * @param theme A hex color. Using 'light' or 'dark' will trigger CommandBar's default light or dark theme.
   * @param [primaryColor] A hex color.
   */
  setTheme(theme: string | 'light' | 'dark' | Pick<ISkinType, 'logo' | 'skin'>, primaryColor?: string): void;
  /**
   * Returns dictionary representing the current callbacks currently stored within CommandBar. The keys represent
   * callback keys, and the values are the supplied functions.
   */
  shareCallbacks(): Record<string, CallbackFunction<unknown>>;

  /**
   * Returns dictionary representing the Custom Components currently defined (via addComponent).
   *
   * The keys represent component keys, and the value is the name of the component.
   */
  shareComponentNamesByKey(): Record<string, string>;

  /** Returns the key value dictionary representing the current state of context */
  shareContext(): Metadata;

  /**
   * Get information about the current status of CommandBar
   */
  shareState(): { isBooted: boolean; currentInput: string; config: { helphub_enabled: boolean } };

  /** Returns whether or not the end user is successfully verified using HMAC */
  isUserVerified(): boolean;
  /**
   * Make CommandBar unavailable to the user. Does nothing unless `.boot` has been called. `shutdown` is typically used
   * to make CommandBar unavailable after logout.
   */
  shutdown(): void;
  /**
   * Manually trigger client search functions.
   *
   * Allows search functions to be re-triggered with the same input text.
   */
  triggerSearchFunctions(): void;
  /**
   * Manually trigger checklist by id.
   */
  triggerChecklist(id: number): void;
  /** @deprecated Use addContext instead. */
  updateContextSettings(key: string, settings: IRecordSettings): void;
  /**
   * Completely remove CommandBar from the page. You will need to re-run the snippet or call the init() function again
   * (depending on your deployment method) to load CommandBar again.
   */
  unmount(): void;
  /**
   * Changes the current default theme to the light- or dark-mode version.
   * If 'auto', it will choose the user-preferred color-scheme
   *
   * @param mode A string 'light_mode', 'dark_mode', or 'auto'
   */
  setThemeMode(mode: 'light_mode' | 'dark_mode' | 'auto'): void;
}>;
