import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import { respondSuccess, respondError } from '@commandbar/internal/client/usePortal';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { getSDK, getProxySDK } from '@commandbar/internal/client/globals';
import {
  _configuration,
  _configUser,
  _programmaticTheme,
  _setThemeV2,
  _showWidgetTableau,
  _user,
} from '@commandbar/internal/client/symbols';
import Logger from '@commandbar/internal/util/Logger';
import { CommandBarProxyGlobal } from '@commandbar/internal/client/CommandBarProxySDK';
import { runChecks } from '../util/checks';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { checkSelectorAndVisibility } from '../util/DOM';
import { getSentry } from '@commandbar/internal/util/sentry';

//////////////////////////////////////////////////
// Editor Receivers
//////////////////////////////////////////////////

const client: ReceiverFunctionType = async ({ data }) => {
  const { target: _, ...toSend } = data;

  const params = toSend?.data ?? [];
  const method = toSend?.method as keyof CommandBarProxyGlobal;

  try {
    const sdk = getProxySDK();
    const fn = sdk[method] || sdk[Symbol.for(`CommandBar::${String(method)}`) as keyof CommandBarProxyGlobal];
    let result;
    if (fn instanceof Function) {
      result = (fn as Function)(...params);
    } else if (fn != null) {
      result = fn;
    } else {
      throw new Error('unknown method');
    }
    return respondSuccess({ result, method });
  } catch (err: unknown) {
    Logger.error('Proxy client receiver error', err);
    getSentry()?.captureException(err);
    return respondError(String(err));
  }
};

const onCMDK: ReceiverFunctionType = () => {
  const sdk = getSDK();
  if (isStudioPreview()) {
    sdk.isOpen() ? sdk.close() : sdk.open();
  }
  return respondSuccess();
};

const synToken: ReceiverFunctionType = ({ data }) => {
  const { token } = data?.data;

  if (!!token) {
    LocalStorage.set('token', token);

    if (!isStudioPreview()) {
      // Don't auto-open the editor if we're in the standalone editor
      LocalStorage.set('editor', '1');
    }

    getSDK()[_configUser]();
  } else {
    LocalStorage.remove('token');
  }

  return respondSuccess();
};

const secureEditor: ReceiverFunctionType = () => {
  if (!isStudioPreview()) {
    // Don't auto-open the editor if we're in the standalone editor
    LocalStorage.set('editor', '1');
  }
  return respondSuccess();
};

const logout: ReceiverFunctionType = () => {
  LocalStorage.remove('token');
  LocalStorage.remove('editor');
  getSDK()[_configUser]();
  return respondSuccess();
};

const shareHostURL: ReceiverFunctionType = () => {
  return respondSuccess({ url: window.location.href });
};

const shareLogs: ReceiverFunctionType = () => {
  return respondSuccess({ logs: (window as any).CommandBarLogs || [] });
};

const getProgrammaticTheme: ReceiverFunctionType = () => {
  return respondSuccess({ data: getSDK()[_programmaticTheme] });
};

const shareConfiguration: ReceiverFunctionType = () => {
  return respondSuccess({ data: getSDK()[_configuration] });
};

const shareUser: ReceiverFunctionType = () => {
  return respondSuccess({ data: getSDK()[_user] });
};

const shareChecks: ReceiverFunctionType = async () => {
  const results = await runChecks();

  return respondSuccess({ data: results });
};

const shareIsOpen: ReceiverFunctionType = () => {
  return respondSuccess({ isOpen: getSDK().isOpen() });
};

const shareCallbacks: ReceiverFunctionType = () => {
  const callbacks = getSDK().shareCallbacks() || {};
  return respondSuccess({ callbacks: Object.keys(callbacks) });
};

const shareComponentNamesByKey: ReceiverFunctionType = () => {
  const componentNamesByKey = getSDK().shareComponentNamesByKey() || {};
  return respondSuccess({ components: componentNamesByKey });
};

const setTheme: ReceiverFunctionType = ({ data }) => {
  const slug = data?.data;
  getSDK().setTheme(slug);
  return respondSuccess({});
};

const setThemeV2: ReceiverFunctionType = ({ data }) => {
  if (!data || !data.data) return;
  getSDK()[_setThemeV2](data.data.themeV2, data.data.mode);
  return respondSuccess({});
};

const showWidgetTableau: ReceiverFunctionType = ({ data }) => {
  if (!data) return;
  getSDK()[_showWidgetTableau](Boolean(data.data));
  return respondSuccess({});
};

const shareOrganizationID: ReceiverFunctionType = () => {
  const { uuid } = getSDK()[_configuration];
  return respondSuccess({ data: uuid });
};

const checkIfSelectorValid: ReceiverFunctionType = ({ data }) => {
  const selector = data?.data;
  const result = checkSelectorAndVisibility(selector);
  return respondSuccess({ elementFound: result.elementFound, elementVisible: result.elementVisible });
};

const Receiver = {
  editor: {
    client,
    logout,
    synToken,
    onCMDK,
    shareHostURL,
    shareLogs,
    getProgrammaticTheme,
    shareUser,
    shareIsOpen,
    shareCallbacks,
    shareComponentNamesByKey,
    checkIfSelectorValid,
    shareChecks,
    secureEditor,
    setTheme,
    setThemeV2,
    showWidgetTableau,
    shareConfiguration,
    shareOrganizationID,
  },
};

export default Receiver;
