import React, { useEffect } from 'react';

import Editor from './Editor';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import { DEBUG_NUDGE_PARAM, isStudioPreview } from '@commandbar/internal/util/location';
import Receiver from './management/Receiver';
import Sender from './management/Sender';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { ShareExtensionStatusMessage } from '@commandbar/internal/client/extension/messages';
import { AppGlobalContext } from './AppContext';
import Recorder from '@commandbar/internal/client/Recorder';
import { _configuration, _setEditorVisible } from '@commandbar/internal/client/symbols';
import { getSDK } from '@commandbar/internal/client/globals';
import { getSentry } from '@commandbar/internal/util/sentry';
import { PREVIEW_ELEMENT_ID } from '@commandbar/internal/client/extension/preview';
import { RECORDER_ELEMENT_ID } from '@commandbar/internal/client/extension/recorder';

const App = () => {
  const [isOpen_Editor, setIsOpen_Editor] = React.useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const previewNudgeId = urlParams.get(DEBUG_NUDGE_PARAM);

    // If we are previewing a nudge after opening in a new tab, don't open the editor
    if (previewNudgeId) {
      return false;
    }

    return !!LocalStorage.get('editor.open', false);
  });
  const [isSummoned_Editor, setIsSummoned_Editor] = React.useState(false);
  const [isExtensionRecorderOnPage, setExtensionRecorderOnPage] = React.useState(
    () => !!document.getElementById(RECORDER_ELEMENT_ID),
  );
  const [isExtensionPreviewOnPage, setExtensionPreviewOnPage] = React.useState(
    () => !!document.getElementById(PREVIEW_ELEMENT_ID),
  );

  /** Click recorder state **/
  const [isOpen_Recorder, setIsOpen_Recorder] = React.useState(false);
  const [initialRecorderProps, setInitialRecorderProps] = React.useState({
    id: -1,
    selectors: [],
    singleStep: false,
  });

  // Global message handler
  usePortal(Receiver);

  const globalStateManagementContext = {
    isOpen_Editor,
    isSummoned_Editor,
    isStudioPreview: isStudioPreview(),
  };

  const notifyEditorVisibility = async (visible: boolean) => {
    if (isExtensionPreviewOnPage || isExtensionRecorderOnPage) return;

    const loaded = await Sender.waitForEditorToLoad(5000);
    if (!loaded) return;
    await Sender.onEditorVisibleChange(visible);
  };

  const changeEditorVisible = async (visible: boolean) => {
    await notifyEditorVisibility(visible);

    getSentry()?.addBreadcrumb({ category: 'editor', message: 'Change editor visible', data: { visible } });
    setIsOpen_Editor(visible);
  };

  useEffect(() => {
    const unsub = ShareExtensionStatusMessage.addPageListener((data) => {
      setExtensionPreviewOnPage(data.preview);
      setExtensionRecorderOnPage(data.recorder);
    });

    return () => {
      unsub();
    };
  }, []);
  React.useEffect(() => {
    const sdk = getSDK();
    if (sdk[_setEditorVisible]) {
      sdk[_setEditorVisible](isOpen_Editor);
    }
  }, [isOpen_Editor]);

  const toggleEditorVisible = () => changeEditorVisible(!isOpen_Editor);

  /************************************
   * Editor message handler
   ************************************/

  const showEditor: ReceiverFunctionType = ({ data }) => {
    getSentry()?.addBreadcrumb({ category: 'editor', message: 'Show Editor', data });
    setIsSummoned_Editor(true);
    changeEditorVisible(true);
    return respondSuccess();
  };

  const hideEditor: ReceiverFunctionType = ({ data }) => {
    getSentry()?.addBreadcrumb({ category: 'editor', message: 'Hide Editor', data });
    setIsSummoned_Editor(false);
    changeEditorVisible(false);
    return respondSuccess();
  };

  const openClickRecorder: ReceiverFunctionType = ({ data }) => {
    getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Open Recorder', data });
    setIsOpen_Recorder(true);
    changeEditorVisible(false);
    setInitialRecorderProps({
      id: data?.id || -1,
      selectors: data?.selectors || [],
      singleStep: data?.singleStep || false,
    });
    return respondSuccess();
  };

  const onClickRecorderComplete = (selectors: string[]) => {
    getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Complete Recorder', data: { selectors } });
    if (selectors.length > 0) {
      Sender.onClickRecorderComplete(selectors, initialRecorderProps.id);
    }
    setIsOpen_Recorder(false);
    changeEditorVisible(true);
    setInitialRecorderProps(() => ({
      ...initialRecorderProps,
      selectors: [],
    }));
  };

  const receiver = React.useMemo(() => {
    return {
      editor: {
        showEditor,
        hideEditor,
        openClickRecorder,
      },
    };
  }, [isOpen_Editor]);

  React.useEffect(() => {
    LocalStorage.set('editor.open', isOpen_Editor);
  }, [isOpen_Editor]);

  React.useEffect(() => {
    // When the component mounts, notify the Editor of the initial visibility state
    if (!isStudioPreview()) notifyEditorVisibility(isOpen_Editor);
  }, []);

  usePortal(receiver);

  /************************************/

  return (
    <div id="proxy-mount" style={{ display: 'flex' }}>
      <AppGlobalContext.Provider value={globalStateManagementContext}>
        {isStudioPreview() ? null : (
          <>
            {isOpen_Recorder && (
              <Recorder
                onSave={onClickRecorderComplete}
                selectors={initialRecorderProps.selectors}
                singleStep={initialRecorderProps.singleStep}
              />
            )}
            {!isExtensionPreviewOnPage && !isExtensionRecorderOnPage && (
              <Editor src={getSDK()[_configuration].editor} open={isOpen_Editor} toggleEditor={toggleEditorVisible} />
            )}
          </>
        )}
      </AppGlobalContext.Provider>
    </div>
  );
};

export default App;
