import Portal from '@commandbar/internal/client/Portal';

/*
 * Editor
 */
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const waitForEditorToLoad = async (timeout = 1500 /*ms*/): Promise<any> => {
  const msg = {
    action: 'waitForEditorToLoad',
    sender: 'proxy',
  };

  const start = Date.now();
  while (Date.now() - start < timeout) {
    const result = await Promise.race([
      Portal.openChannel('editor')(msg).then(() => true),
      sleep(250).then(() => false),
    ]);
    if (result) {
      return true;
    }
    await sleep(100);
  }

  return false;
};

const onEditorVisibleChange = async (visible: boolean): Promise<any> => {
  const msg = {
    action: 'onEditorVisibleChange',
    data: visible,
    sender: 'proxy',
  };

  return await Portal.openChannel('editor')(msg);
};

const onClickRecorderComplete = async (selectors: string[], id: number) => {
  const msg = {
    action: 'onClickRecorderComplete',
    selectors,
    id,
    sender: 'proxy',
  };

  return await Portal.openChannel('editor')(msg);
};

const onRouteChange = (selectedRoute: string, type: 'replace' | 'push' = 'push') => {
  const msg = {
    action: 'onRouteChange',
    selectedRoute,
    type,
    sender: 'proxy',
  };

  return Portal.openChannel('editor')(msg);
};

const Sender = {
  waitForEditorToLoad,
  onEditorVisibleChange,
  onClickRecorderComplete,
  onRouteChange,
};

export default Sender;
