// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const ChevronRightFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 5 8" {...props}>
    <path
      fill="#7A7A85"
      fillRule="evenodd"
      d="M1.1465 7.3536c-.1953-.1953-.1464-.7072-.1464-.7072V1.3537s-.0489-.512.1464-.7073a.5.5 0 0 1 .7071 0l3 3a.5.5 0 0 1 0 .7072l-3 3a.5.5 0 0 1-.7071 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ChevronRightFilled;
