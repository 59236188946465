/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import styled from '@emotion/styled';

import { finder } from './finder_fork';

import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';
import { RecorderFocusMask, TRecorderFocusMask } from './RecorderFocusMask';
import { getSentry } from '@commandbar/internal/util/sentry';

/*******************************************************************************/
/* Styles
/*******************************************************************************/

const Info = styled.div<{ hide: boolean }>`
  box-sizing: border-box;
  position: fixed;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  padding: 12px 16px;
  border-radius: 100px;
  background-color: #0a0a0f;
  color: #fff;
  text-align: center;
  pointer-events: none;

  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`;

const mouseWithin = (element: HTMLElement | null, { clientX, clientY }: MouseEvent) => {
  if (element) {
    const { width, height, top, left } = element.getBoundingClientRect();
    const maxX = left + width;
    const maxy = top + height;

    return clientY <= maxy && clientY >= top && clientX <= maxX && clientX >= left;
  }

  return false;
};

const getNudgeDisplayValues = () => {
  // .rc-dialog-root selects the modal form-factor including its mask
  const nudgeSelectors = [
    '#commandbar-nudge-container',
    '.rc-dialog-root',
    '[data-testid="commandbar-nudge-mask"]',
    '[data-testid="commandbar-nudge-mask-inner"]',
  ];

  return nudgeSelectors
    .map((selector) => {
      const element = document.querySelector(selector);

      if (element instanceof HTMLElement) {
        const originalDisplay = element.style.display;

        return {
          element,
          originalDisplay: originalDisplay === '' ? 'unset' : originalDisplay,
        };
      }

      return null;
    })
    .filter((nudgeData): nudgeData is { element: HTMLElement; originalDisplay: string } => Boolean(nudgeData));
};

const hideNudges = (nudges: Array<{ element: HTMLElement; originalDisplay: string }>) => {
  nudges.forEach(({ element }) => {
    element.style.display = 'none';
  });
};

/*******************************************************************************/
/* Props
/*******************************************************************************/
interface IProps {
  onSave: (selectors: string[]) => void;
  selectors: string[];
  singleStep: boolean;
}

interface IState {
  isContinuingRecording: boolean;
  isFindingCSS: boolean;
  selectors: string[];
  nudges: { element: HTMLElement; originalDisplay: string }[];
  recorderMask: HTMLDivElement;
  hideInfo: boolean;
  mask: TRecorderFocusMask;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/
class Recorder extends React.Component<IProps, IState> {
  private focusElement?: HTMLElement;
  public constructor(props: IProps) {
    super(props);

    this.focusElement = undefined;

    const nudges = getNudgeDisplayValues();
    hideNudges(nudges);

    const recorderMask = document.createElement('div');
    recorderMask.setAttribute('id', 'commandbar-recorder-mask');

    this.state = {
      isContinuingRecording: false,
      isFindingCSS: true,
      selectors: props.selectors,
      nudges,
      recorderMask,
      hideInfo: false,
      mask: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        display: 'none',
        selector: undefined,
      },
    };
  }

  public componentDidUpdate(prevProps: IProps, _prevState: IState) {
    if (!this.arraysEqual(prevProps.selectors, this.props.selectors)) {
      this.setState({ selectors: this.props.selectors });
    }
  }

  /*********** Workaround to focus on this component on mount **********/
  private stealFocus = () => {
    const wrapper = document.getElementById('commandbar-recorder-mask');
    this.focusElement = document.createElement('div');
    this.focusElement.style.position = 'fixed';
    this.focusElement.tabIndex = -1;

    if (wrapper) {
      wrapper.appendChild(this.focusElement);
      this.focusElement.focus();
    }
  };

  private removeFocusElement = () => {
    const wrapper = document.getElementById('commandbar-recorder-mask');
    if (this.focusElement && wrapper) {
      wrapper.removeChild(this.focusElement);
    }
  };
  /********************** Set up and tear down event handlers **********/
  public setupWrapperOnMount = () => {
    document.body.appendChild(this.state.recorderMask);
  };

  public componentDidMount = () => {
    this.setupWrapperOnMount();
    this.stealFocus();

    // Add mouseover listeners
    document.addEventListener('click', this.disableEvents, true);
    document.addEventListener('mouseover', this.onMouseOverElement);
    document.addEventListener('mousedown', this.onMouseDownElement);
    document.addEventListener('keydown', this.handleKeydown);
    document.addEventListener('keyup', this.handleKeyup);
    document.addEventListener('mousemove', this.toggleInfoDisplay);
  };

  public componentWillUnmount = () => {
    this.removeFocusElement();

    // Remove mouseover listeners
    document.removeEventListener('mouseover', this.onMouseOverElement);
    document.removeEventListener('mousedown', this.onMouseDownElement);
    document.removeEventListener('keydown', this.handleKeydown);
    document.removeEventListener('keyup', this.handleKeyup);
    document.removeEventListener('mousemove', this.toggleInfoDisplay);

    /**
     * HACK/PLEASE FIX ME:
     * For some currently unknown reason, the unmount is happening
     * too soon for what we want to accomplish.
     * ie. We would like prevent other click/change events on the page
     * from occuring while the Recorder is active. However, without this timeout,
     * the last button/input interacted with will trigger
     */
    setTimeout(() => {
      document.removeEventListener('click', this.disableEvents, true);
    }, 500);

    document.body.removeChild(this.state.recorderMask);
  };

  /********************** Core functions **********/

  public cancel = () => {
    getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Recorder cancel' });
    // eslint-disable-next-line react/no-direct-mutation-state
    this.setState({ mask: { ...this.state.mask, display: 'none' } });
    this.setState({ selectors: [], isFindingCSS: false });

    this.state.nudges.forEach(({ element, originalDisplay }) => {
      element.style.display = originalDisplay;
    });

    this.props.onSave([]);
  };

  public finish = () => {
    getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Recorder finish' });
    // eslint-disable-next-line react/no-direct-mutation-state
    this.setState({ mask: { ...this.state.mask, display: 'none' } });
    this.setState({ isFindingCSS: false });
  };

  public save = () => {
    if (this.state.isContinuingRecording) {
      return;
    }
    this.state.nudges.forEach(({ element, originalDisplay }) => {
      element.style.display = originalDisplay;
    });

    getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Recorder save' });
    // eslint-disable-next-line react/no-direct-mutation-state
    this.setState({ mask: { ...this.state.mask, display: 'none' } });
    this.setState({ isFindingCSS: false });

    this.props.onSave(this.state.selectors);
  };

  public start = () => {
    getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Recorder start' });
    this.setState({ isFindingCSS: true });
  };

  /********************** Event handlers **********************/

  public disableEvents = (e: MouseEvent) => {
    if (this.state.isContinuingRecording) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
  };

  // Shortcuts to save, escape, pause, play
  public handleKeydown = (e: KeyboardEvent) => {
    const triggerKey = getTriggerKey(e);
    /**
     * this is to allow chaining
     */
    if (e.key === 'Shift') {
      this.setState({ isContinuingRecording: true });
    }

    if (triggerKey && e.key === 's') {
      getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Recorder shortcut save' });
      e.preventDefault();
      e.stopPropagation();
      this.save();
    }

    if (e.key === 'Escape') {
      getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Recorder shortcut escape' });
      e.preventDefault();
      e.stopPropagation();
      this.cancel();
    }
    if (e.code === 'Space') {
      getSentry()?.addBreadcrumb({ category: 'recorder', message: 'Recorder shortcut space' });
      e.preventDefault();
      e.stopPropagation();

      if (this.state.isFindingCSS) {
        this.finish();
      } else {
        this.start();
      }
    }
  };

  public handleKeyup = (e: KeyboardEvent) => {
    if (e.key === 'Shift') {
      this.setState({ isContinuingRecording: false }, () => {
        /**
         * save and close recording  mode if at least 1 pin has been selected
         * else, let user continue in this mode
         */
        if (this.state.selectors.length > 0) {
          this.save();
        }
      });
    }
  };

  public moveMaskOverElement = (element: HTMLElement, selector?: string) => {
    // grab fixed coordinates of hovered element
    const top = element.getBoundingClientRect().top;
    const left = element.getBoundingClientRect().left;
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    this.setState({
      mask: {
        ...this.state.mask,
        // show mask box (in case it was previously hidden)
        // eslint-disable-next-line react/no-direct-mutation-state
        display: 'block',
        // move mask box over hovered element
        top,
        left,
        width,
        height,
        selector,
      },
    });
  };

  public getSelector(el: EventTarget | null): string | undefined {
    if (el instanceof Element) {
      if (this.ignoreElement(el)) {
        return;
      }
      if (el.id === 'commandbar-editor-wrapper') {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.setState({ mask: { ...this.state.mask, display: 'none' } });
        return;
      }

      if (this.ignoreElement(el)) {
        return;
      }

      return finder(el);
    }
    return;
  }

  public onMouseOverElement = (e: MouseEvent) => {
    // if element is not in editor
    if (this.state.isFindingCSS) {
      // eslint-disable-next-line commandbar/no-event-target
      const el = e.target;

      if (el instanceof HTMLElement) {
        if (this.ignoreElement(el)) {
          return;
        }
        const selector = this.getSelector(el);
        this.moveMaskOverElement(el, selector);
      }
    }
  };

  public onMouseDownElement = (e: MouseEvent) => {
    if (this.state.isFindingCSS) {
      // eslint-disable-next-line commandbar/no-event-target
      const el = e.target;

      if (el instanceof Element) {
        if (this.ignoreElement(el)) {
          return;
        }
        if (el.id === 'commandbar-editor-wrapper') {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.setState({ mask: { ...this.state.mask, display: 'none' } });
          return;
        }

        if (this.ignoreElement(el)) {
          return;
        }

        const selector = finder(el);

        this.setState(
          (prevState) => ({
            selectors: this.props.singleStep ? [selector] : [...prevState.selectors, selector],
          }),
          () => {
            this.save();
          },
        );
      }

      // hide mask box
      // eslint-disable-next-line react/no-direct-mutation-state
      this.setState({ mask: { ...this.state.mask, display: 'none' } });
      e.preventDefault();
      e.stopPropagation();
    }
  };

  public toggleInfoDisplay = (e: MouseEvent) => {
    const infoElement = document.getElementById('commandbar-recorder-message');
    const mouseWithinInfo = mouseWithin(infoElement, e);

    if (mouseWithinInfo && !this.state.hideInfo) {
      this.setState({ ...this.state, hideInfo: true });
    } else if (!mouseWithinInfo && this.state.hideInfo) {
      this.setState({ ...this.state, hideInfo: false });
    }
  };

  // figure out if we're hovering over the recorder controls or the app DOM
  public ignoreElement = (el: Element) => {
    const recorder = document.getElementById('commandbar-recorder-message');
    if (recorder && recorder.contains(el)) return true;

    const editor = document.getElementById('commandbar-editor-drawer');
    if (editor && editor.contains(el)) return true;

    const commandbar = document.getElementById('commandbar');
    return commandbar && commandbar.contains(el);
  };

  public arraysEqual<A, B>(a: A[], b: A[] | B[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  public render() {
    return (
      <>
        <Info id="commandbar-recorder-message" hide={this.state.hideInfo}>
          Choose a target element or press <b>ESC</b> to cancel
        </Info>
        <RecorderFocusMask {...this.state.mask} />
      </>
    );
  }
}

export default Recorder;
