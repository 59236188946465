import React from 'react';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { useGlobalAppContext } from './AppContext';
import { ChevronLeftFilled, ChevronRightFilled } from '@commandbar/design-system/icons/react';

interface IProps {
  isEditorOpen: boolean;
  toggleEditor: () => void;
  isEditorLoggedIn: boolean;
  organization: IOrganizationType | null;
  releaseBadgeStatus: boolean;
}

const MIN_Y = 50;

// NOTE: this is needed because the rc-drawer "Drawer" component passes a "ref" to the handle, and React functional components don't support refs
class EditorHandle extends React.Component<IProps> {
  render() {
    // eslint-disable-next-line react/jsx-pascal-case
    return <_EditorHandle {...this.props} />;
  }
}

const _EditorHandle = (props: IProps) => {
  const isMouseDown = React.useRef(false);
  const isMovingHandle = React.useRef(false);
  const yPositionAtMouseDown = React.useRef(null);
  const yOffsetAtMouseDown = React.useRef<number | null>(null);
  const globalAppContext = useGlobalAppContext();

  const handleY = React.useRef<number>(parseInt(LocalStorage.get('handleY', '55').toString()));
  const [, forceUpdate] = React.useReducer(() => ({}), {});

  const top = Math.max(handleY.current, MIN_Y);

  // Set a minimum mousemove delta to process a move
  // This avoids annoying situations where a user clicks a few pixels away and
  //    the click is processed as a drag
  const MIN_DELTA = 100;

  const onMouseDown = (e: any) => {
    isMouseDown.current = true;
    yPositionAtMouseDown.current = e.clientY;
    yOffsetAtMouseDown.current = e.clientY - top;
  };

  const onMouseMove = (e: any) => {
    if (!isMouseDown.current) {
      return;
    }

    // Only process a move if it meets the minimum threshold
    const amountMovedIsSignificant =
      Math.abs((yPositionAtMouseDown.current || handleY.current) - e.clientY) > MIN_DELTA;
    if (amountMovedIsSignificant) {
      isMovingHandle.current = true;
    }
    const newPosition = e.clientY;
    const newPositionIsInView = newPosition < window.innerHeight && window.innerHeight - newPosition > MIN_DELTA;
    if (newPositionIsInView) {
      handleY.current = newPosition - (yOffsetAtMouseDown.current ?? 0);
      forceUpdate();
    }
  };

  const onMouseUp = (e: MouseEvent) => {
    if (isMouseDown.current) {
      if (isMovingHandle.current) {
        e.stopPropagation();
        isMovingHandle.current = false;
        LocalStorage.set('handleY', handleY.current);
      }
      isMouseDown.current = false;
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    // NOTE: we bind this to "click" instead, so we can cancel the client event if the handle was being moved, and is no longer being moved.
    // This prevents the "onClick" event on the div from firing, so the drawer will not open in the case where you drag the handle.
    document.addEventListener('click', onMouseUp, { capture: true });

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('click', onMouseUp, { capture: true });
    };
  }, []);

  if (globalAppContext?.isStudioPreview) return null;

  return (
    <div
      onClick={() => {
        if (!isMovingHandle.current) {
          // If the handle is being moved, don't process a click
          // props.dispatch.setRecorderCommand(undefined);
          props.toggleEditor();
        }
      }}
      onMouseDown={onMouseDown}
      className="commandbar-editor-drawer-handle"
      data-testid="commandbar-editor-drawer-handle"
      style={{
        borderRadius: '8px 0px 0px 8px',
        top,
        cursor: 'pointer',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        id="editor_nav"
        style={{
          border: `0.5px solid #0000001a`,
          borderRadius: '8px 0px 0px 8px',
          boxShadow: `0px 16px 24px rgba(36, 35, 41, 0.1), 0px 2px 4px rgba(36, 35, 41, 0.1), -2px 0px 4px 0px #0000000f inset`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          position: 'relative',
          width: '100%',
          flex: 1,
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: 10,
            height: '100%',
            left: 0,
            cursor: 'grab',
          }}
        ></div>
        {props.isEditorOpen ? <ChevronRightFilled height={10} /> : <ChevronLeftFilled height={10} />}
      </div>
    </div>
  );
};

export default EditorHandle;
