// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const ChevronLeftFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 5 8" {...props}>
    <path
      fill="#7A7A85"
      fillRule="evenodd"
      d="M4.8481 7.3536c.1953-.1953.1465-.7072.1465-.7072V1.3537s.0488-.512-.1466-.7073a.5.5 0 0 0-.707 0l-3 3a.5.5 0 0 0 0 .7072l3 3a.5.5 0 0 0 .707 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ChevronLeftFilled;
