import { createContext, useContext } from 'react';

export interface IGlobalAppContext {
  isOpen_Editor: boolean;
  isSummoned_Editor: boolean;
  isStudioPreview: boolean;
}

export const AppGlobalContext = createContext<IGlobalAppContext | null>(null);

export const useGlobalAppContext = () => useContext(AppGlobalContext);
