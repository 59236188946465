import { COMMANDBAR_CHROME_EXTENSION_ID } from '../../ChromeExtension';
import { isValidStudioOrigin } from './helpers';

type MessageResponse<Response> =
  | (Response extends void ? { status: 'ok' } : { status: 'ok'; data: Response })
  | { status: 'error'; error?: string };

type MessageRequest<Payload> = {
  message: string;
} & (Payload extends void ? { message: string } : { message: string; data: Payload });

type MessageListener<Payload, Response> = (
  request: MessageRequest<Payload>,
  sender: chrome.runtime.MessageSender,
) => Promise<MessageResponse<Response>> | MessageResponse<Response>;

type PrivateMessageListener<Payload, Response> = (
  request: MessageRequest<Payload>,
  sender: chrome.runtime.MessageSender,
  sendResponse: (response: MessageResponse<Response>) => void,
) => void;

type ExtensionMessageType<T> = T extends ExtensionMessage<infer Payload, infer Response>
  ? { payload: Payload; response: Response; message: T['message'] }
  : never;

type ExtensionMessageListener<T extends ExtensionMessage<any, any>> = MessageListener<
  ExtensionMessageType<T>['payload'],
  ExtensionMessageType<T>['response']
>;

type ExtensionMessagePayload<T extends ExtensionMessage<any, any>> = ExtensionMessageType<T>['payload'];
type ExtensionMessageResponse<T extends ExtensionMessage<any, any>> = ExtensionMessageType<T>['response'];

class ExtensionMessage<Payload = void, Response = void> {
  message: string;

  private static listeners = new Map<string, PrivateMessageListener<any, any>>();
  private static externalListeners = new Map<string, PrivateMessageListener<any, any>>();
  private static pageListeners = new Map<string, ((event: MessageEvent) => void)[]>();
  // Static flag to track if global chrome runtime listener is initialized
  private static globalListenerInitialized = false;
  private static globalStudioListenersInitialized = false;
  private static globalPageListenerInitialized = false;

  constructor(message: string) {
    this.message = message;
  }

  /**
   * Sends a message to the extension from outside of the extension
   * It is only allowed to be called from the Studio, see manifest.json/externally_connectable
   * @returns response from the extension
   */
  sendToExtensionFromStudio(payload: Payload): Promise<MessageResponse<Response>> {
    return new Promise((resolve, reject) => {
      chrome?.runtime?.sendMessage(
        COMMANDBAR_CHROME_EXTENSION_ID,
        this.buildPayload(payload),
        this.wrapResponseHandler(resolve, reject),
      );
    });
  }

  /**
   * Sends a message to the content scripts running on a specific tab
   * It is only allowed to be called from the background script or the popup
   * @returns response from a content script
   */
  sendToTabFromBackground(tabId: number, payload: Payload): Promise<MessageResponse<Response>> {
    return new Promise((resolve, reject) => {
      chrome?.tabs?.sendMessage(tabId, this.buildPayload(payload), this.wrapResponseHandler(resolve, reject));
    });
  }

  /**
   * Sends a message to the current page, not the content scripts
   * It is only allowed to be called from the background script or the popup
   *
   * Messages from Chrome extensions must use the `window.postMessage` API to communicate with the page
   * and therefore never has a response associated with it
   *
   * @returns there will never be a response
   */
  async sendToPageFromBackground(tabId: number, payload: Payload): Promise<void> {
    const _payload = this.buildPayload(payload);
    const _data = 'data' in _payload ? _payload.data : {};
    const serializedData = encodeURIComponent(JSON.stringify(_data));
    await chrome.scripting.executeScript({
      target: { tabId },
      func: function (message, serializedData) {
        postMessage({ message, data: JSON.parse(decodeURIComponent(serializedData)) }, '*');
      },
      args: [_payload.message, serializedData],
    });
  }

  /**
   * Sends a message to the current page
   * It is only allowed to be called from the content scripts or CommandBar
   */
  sendToPage(payload: Payload) {
    const _payload = this.buildPayload(payload);
    window?.postMessage(JSON.parse(JSON.stringify(_payload)), '*');
  }

  /**
   * Sends a message internally within the extension
   * It is only allowed to be called from the content scripts or the background script
   * @returns response from the background script
   */
  sendToBackgroundFromContent(payload: Payload): Promise<MessageResponse<Response>> {
    return new Promise((resolve, reject) => {
      chrome?.runtime?.sendMessage(this.buildPayload(payload), this.wrapResponseHandler(resolve, reject));
    });
  }

  /**
   * Registers a listener to listen for messages from within the extension
   * and registers with the global chrome runtime listener
   * @returns unsubscribe function
   */
  addListener(handler: MessageListener<Payload, Response>) {
    ExtensionMessage.setupGlobalListener();

    const _handler = this.wrapAsyncHandler(handler);
    ExtensionMessage.listeners.set(this.message, _handler);
    return () => ExtensionMessage.listeners.delete(this.message);
  }

  /**
   * Registers a listener to listen for messages coming from outside of the extension
   * and registers with the global chrome runtime listener
   *
   * It is only allowed to be registered within background scripts and will only receive messages from the Studio
   * see manifest.json/externally_connectable
   *
   * @returns unsubscribe function
   */
  addStudioListener(handler: MessageListener<Payload, Response>) {
    ExtensionMessage.setupGlobalStudioListener();

    const _handler = this.wrapAsyncHandler((request, sender) => {
      if (!isValidStudioOrigin(sender.origin)) return { status: 'error', error: 'Invalid origin' };

      return handler(request, sender);
    });

    ExtensionMessage.externalListeners.set(this.message, _handler);
    return () => ExtensionMessage.externalListeners.delete(this.message);
  }

  /**
   * Registers a listener to listen for messages coming from the current page
   * and registers with the global page listener
   *
   * Given the more indirect nature of window.postMessage, many listeners for the same message type can be registered
   * and all of the listeners will be called when the message is received
   *
   * It is only allowed to be registered within a content script or on the page itself (ie. CommandBar/Studio/Proxy/Editor)
   * @returns unsubscribe function
   */
  addPageListener(handler: (event: Payload) => void) {
    ExtensionMessage.setupGlobalPageListener();

    const listeners = ExtensionMessage.pageListeners.get(this.message) || [];

    const listener = (event: MessageEvent) => {
      handler(event.data);
    };

    listeners.push(listener);
    ExtensionMessage.pageListeners.set(this.message, listeners);

    return () => {
      const listeners = ExtensionMessage.pageListeners.get(this.message) || [];
      const index = listeners.indexOf(listener);
      if (index > -1) {
        listeners.splice(index, 1);
      }
      if (listeners.length > 0) {
        ExtensionMessage.pageListeners.set(this.message, listeners);
      } else {
        ExtensionMessage.pageListeners.delete(this.message);
      }
    };
  }

  buildPayload(payload: Payload): MessageRequest<Payload> {
    if (payload === undefined) return { message: this.message } as MessageRequest<Payload>;
    return { message: this.message, data: payload } as unknown as MessageRequest<Payload>;
  }

  private wrapAsyncHandler(handler: MessageListener<Payload, Response>): PrivateMessageListener<Payload, Response> {
    const message = this.message;
    return function (request, sender, sendResponse) {
      if (request.message !== message) return;

      const result = handler(request, sender);
      if (result instanceof Promise) {
        result.then(sendResponse).catch((err) => {
          sendResponse({ status: 'error', error: err });
        });
        return true;
      }

      sendResponse(result);
      return false;
    };
  }

  private wrapResponseHandler(
    resolve: (value: MessageResponse<Response> | PromiseLike<MessageResponse<Response>>) => void,
    reject: (value: any) => void,
  ) {
    const message = this.message;
    return function (response: any) {
      if (chrome?.runtime?.lastError) {
        reject(chrome.runtime.lastError);
      } else if (response) {
        if (response.status === 'ok') {
          resolve(response);
        } else {
          reject(response.error ?? `Unknown error sending ${message}`);
        }
      } else {
        reject(`No response received for ${message}`);
      }
    };
  }

  private static setupGlobalListener() {
    // Use the same message listener for all instances of ExtensionMessage
    if (!ExtensionMessage.globalListenerInitialized) {
      chrome?.runtime?.onMessage?.addListener((request, sender, sendResponse) => {
        const listener = ExtensionMessage.listeners.get(request.message);
        if (listener) {
          return listener(request, sender, sendResponse);
        }
      });

      ExtensionMessage.globalListenerInitialized = true;
    }
  }

  private static setupGlobalStudioListener() {
    if (!ExtensionMessage.globalStudioListenersInitialized) {
      chrome?.runtime?.onMessageExternal?.addListener((request, sender, sendResponse) => {
        const listener = ExtensionMessage.externalListeners.get(request.message);
        if (listener) {
          return listener(request, sender, sendResponse);
        }
      });

      ExtensionMessage.globalStudioListenersInitialized = true;
    }
  }

  private static setupGlobalPageListener() {
    if (!ExtensionMessage.globalPageListenerInitialized) {
      window?.addEventListener('message', (event) => {
        const listeners = ExtensionMessage.pageListeners.get(event.data.message);
        if (listeners) {
          listeners.forEach((listener) => listener(event.data));
        }
      });

      ExtensionMessage.globalPageListenerInitialized = true;
    }
  }
}

export type { ExtensionMessagePayload, ExtensionMessageResponse, ExtensionMessageListener };
export { ExtensionMessage };
